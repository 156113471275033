import React, { useState } from 'react';
import { FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import RegisterButton from '../components/RegisterButton';

const LandingPage = () => {
    const { t } = useTranslation();
    const [expandedEvent, setExpandedEvent] = useState(null);

    const handleToggle = (eventId) => {
        setExpandedEvent(expandedEvent === eventId ? null : eventId);
    };

    return (
        <div className='overflow-hidden'>
            {/* hero */}
            <section id="home" className="relative">
                <div
                    className="h-[500px] md:h-fit flex flex-col justify-center items-center text-black pt-16 pb-[2rem] md:pb-[5rem] md:pt-[8rem] bg-img-white relative"
                >
                    <img
                        src="/logo.png"
                        alt="Logo"
                        className="absolute ml-[35%] md:ml-0 h-[300px] md:h-[500px] object-contain z-0 opacity-25 md:opacity-50"
                    />
                    <div className="flex flex-col w-full ml-8 md:ml-20 text-start space-y-8 z-10 relative mt-8 md:mt-0">
                        <div className="flex flex-col items-start mb-[-0.75rem] md:mb-[-1rem]">
                            <img src='/HARLEY_FEST.png' className='w-44 md:w-80' />
                            <p className="text-lg md:text-3xl mt-4 md:mt-8 w-44 md:w-[28rem]">{t("homepage.event")}</p>
                            <p className="text-lg font-semibold md:text-3xl mt-2 mb-4 md:mb-8 text-orange uppercase">
                                {t("locations.1")}, Bali
                                <br />
                                {t("time.date")}
                            </p>
                            <RegisterButton className="bg-black hover:bg-orange-600 hover:text-black text-orange font-bold py-2 px-4 md:px-8 rounded-full text-md md:text-xl uppercase" label={`${t("homepage.register")}`} />
                        </div>
                        <div className="flex space-x-4 md:space-x-6 text-xl md:text-3xl">
                        </div>
                    </div>
                </div>
            </section>

            <section className="px-4 md:px-12 py-8 bg-orange-500 text-black">
                <div className="max-w-screen-md mx-auto flex flex-col justify-center">
                    {/* Event Title and Short Description */}
                    {/* <h2 className="text-2xl md:text-3xl font-bold text-center mb-4">Harley Davidson Event</h2> */}
                    <span className='flex justify-center font-bold text-orange-900'>CONCEPT</span>
                    <p className="text-base md:text-lg text-justify mb-6">
                        {t("desc1")}
                    </p>
                    <span className='flex justify-center font-bold text-orange-900'>OBJECTIVE</span>
                    <p className="text-base md:text-lg text-justify mb-6">
                        {t("desc2")}
                    </p>

                    <hr />

                    {/* Festival Information */}
                    <div className="text-center my-6">
                        <p className="text-sm md:text-lg font-semibold text-orange-900 mb-2">
                            {t("homepage.gates")}
                        </p>
                        <p className="text-xl md:text-xl font-bold text-white">{t("time.clock")}<br />{t("time.date")}</p>
                    </div>

                    <div className="text-center mb-6">
                        <p className="text-sm md:text-lg font-semibold text-orange-900 mb-2">
                            {t("fest_location")}

                        </p>
                        <p className="text-xl text-white md:text-xl font-bold">{t("locations.1")}, Bali, Indonesia</p>
                    </div>

                    {/* Hashtags Section */}
                    <div className="text-center">
                        <p className="text-sm md:text-lg font-semibold text-orange-900 mb-2">
                            {t("fest_follow")}

                        </p>
                        <div className="flex flex-wrap justify-center gap-x-4 text-orange-50 text-sm md:text-base">
                            <span>#HarleyDavidson</span>
                            <span>#HarleyDavidsonAsia</span>
                            <span>#HarleyDavidsonIndonesia</span>
                            <span>#IndonesiaHarleyFest</span>
                            <span>#UnitedWeRide</span>
                        </div>
                        <div className="text-center mt-4">
                            <p className="text-lg">{t("contact3")}</p>
                            <a
                                href="https://www.instagram.com/indonesiaharleyfest"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-center space-x-2 px-4 py-0 text-white rounded-full transition duration-300 font-bold">
                                <FaInstagram className='text-2xl' />
                                <span className="text-xl">@indonesiaharleyfest</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section id="custom-kings" className="relative">
                <div className="bg-img-white">
                    <div className="grid grid-cols-3 gap-[1px] bg-black py-[1px]">
                        <div className="relative group">
                            <img src="/img/ck/1.jpg" alt="Image of a green Harley Davidson motorbike" className="w-full h-[5.5rem] md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                        <div className="relative group">
                            <img src="/img/ck/2.jpg" alt="Image of a black Harley Davidson motorbike" className="w-full h-[5.5rem] md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                        <div className="relative group">
                            <img src="/img/ck/3.jpg" alt="Image of a black Harley Davidson motorbike" className="w-full h-[5.5rem] md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                    </div>

                    <div className="flex items-center justify-center py-8">
                        <img src="/img/custom-kings.png" alt="Logo of Custom Kings" className="h-16 md:h-32 object-contain mr-8" />
                        <div>
                            <p className='text-lg md:text-3xl'>
                                {t('ck1')}
                                <b>Custom Kings Asia</b>
                                <br />
                                {t('ck2')}
                                <br />
                                {t('ck3')}
                            </p>
                            <RegisterButton className="mt-4 bg-black hover:bg-orange-600 hover:text-black text-white font-bold py-2 px-4 md:px-8 rounded-full text-md md:text-xl" label="REGISTER" customKing={true} />
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-[1px] bg-black py-[1px]">
                        <div className="relative group">
                            <img src="/img/ck/4.jpg" alt="Image of a black Harley Davidson motorbike" className="w-full h-18 md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                        <div className="relative group">
                            <img src="/img/ck/5.jpg" alt="Image of a black and red Harley Davidson motorbike" className="w-full h-18 md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                        <div className="relative group">
                            <img src="/img/ck/6.jpg" alt="Image of a black and red Harley Davidson motorbike" className="w-full h-18 md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* guests */}
            {/* <section id="guests" className='relative bg-black pb-12'>
                <div className="h-auto flex flex-col justify-center items-center text-white pt-8 md:pt-16 relative">
                    <h1 className="mb-4 text-3xl">GUESTS & PERFORMANCE</h1>
                    <div className='flex flex-row gap-8'>
                        <Carousel />
                    </div>
                </div>
            </section> */}

            {/* venue */}
            <section id="venue" className="relative bg-img-black pb-12 w-full">
                <h1 className="text-3xl text-end px-8 md:px-16 pt-4 md:pt-10">{t('homepage.join')}</h1>
                <div className="h-auto flex flex-col md:flex-row justify-between items-center text-white pt-4 md:pt-8 relative w-full gap-4">
                    {/* Left Side: Date, Venue Address, and Buttons */}
                    <div className="w-full px-8 md:w-1/3 flex flex-col space-y-6 justify-center">
                        <div className="text-right">
                            <p className="text-xl leading-relaxed">
                                {t("locations.1")}<br />
                                {t("locations.2")}<br />
                                {t("locations.3")}
                            </p>
                        </div>
                        <div className="flex flex-row gap-2 justify-end">
                            <a href='https://maps.app.goo.gl/5YCPHbaR7nK4Lo258'
                                target="_blank"
                                rel="noreferrer"
                                className="border-2 border-orange-400 text-orange font-bold py-2 px-4 text-sm rounded-full hover:bg-orange-400 hover:text-black">
                                {t("homepage.direction")}
                            </a>
                            <RegisterButton className="bg-orange text-black font-bold py-2 px-4 text-sm rounded-full hover:bg-black hover:text-orange-600" label={t("homepage.register")} />
                        </div>
                    </div>

                    {/* Right Side: Map */}
                    <div className="w-full md:w-2/3 mt-4 md:mt-0 px-8 md:pr-16 md:pl-0">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15769.344195802045!2d115.15286994367544!3d-8.848271956835534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach!5e0!3m2!1sen!2sid!4v1732775293955!5m2!1sen!2sid"
                            className="w-full h-72 md:h-96 rounded-lg border-0"
                            allowFullScreen
                            loading="lazy"
                            title="Venue Location"
                        ></iframe>
                    </div>
                </div>
            </section>


            {/* faq */}
            {/* <section id="faq" className="relative bg-img-white pb-4 md:pb-12">
                <div className="h-auto flex flex-col justify-center items-center pt-8 md:pt-16 relative text-black">
                    <div className="w-3/4 md:w-[50rem] text-start mb-4 md:mb-8">
                        <h1 className="">FREQUENTLY ASKED QUESTIONS</h1>
                        <p>{t("faq1")} <b>admin@indonesiaharleyfest.com</b> {t("faq2")}</p>
                    </div>
                    <FAQ />
                </div>
            </section> */}

            {/* sponsors */}
            {/* <section id="sponsors" className="bg-black py-8 z-10 font-sc">
                <div className="container mx-auto px-4">
                    <h1 className="text-white text-2xl md:text-3xl text-center mb-6">SPONSORED BY</h1>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="flex items-center justify-center">
                            <img src="/placeholder.png" alt="Sponsor 1" className="h-12 md:h-24 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/placeholder.png" alt="Sponsor 2" className="h-12 md:h-24 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/placeholder.png" alt="Sponsor 3" className="h-12 md:h-24 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/placeholder.png" alt="Sponsor 4" className="h-12 md:h-24 object-contain" />
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    );
};

export default LandingPage;
