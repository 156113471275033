import React, { useEffect, useState } from 'react';
import { FaCaretDown, FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const HotelRecommendation = () => {
    const { t } = useTranslation();

    const initialHotels = [
        {
            name: 'Pandawa Hill Resort',
            star: 4,
            images: ['/img/hotel/pandawa.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d15769.812603264254!2d115.16073634188065!3d-8.837332606370405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd25b671e4338d5%3A0xeca32c3ec5bf6ea4!2sPandawa%20Hill%20Resort%2C%20Jalan%20Pantai%20Pandawa%2C%20Kutuh%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8369204!2d115.18147169999999!5e0!3m2!1sen!2sid!4v1734378419282!5m2!1sen!2sid',
            description: t("hotel.pandawa"),
            distance: 7.1,
            time: 15,
            promoCode: 'RSV/PHR-PANENMAYAJKT-FEB24',
            bookingNumber: '+62 8585 8911 033 (Debbie)',
            roomTypes: [
                { type: 'Deluxe Room', price: 650, availability: 61 },
                { type: 'Deluxe Room with Bathtub', price: 750, availability: 60 },
                { type: 'Suite Room', price: 850, availability: 4 },
            ],
        },
        {
            name: 'Bali Relaxing Resort Nusa Dua',
            star: 4,
            images: ['/img/hotel/relaxing.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m24!1m8!1m3!1d63083.413558694716!2d115.1931662!3d-8.8129775!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd2430f64c91971%3A0x983c89f4280ffa82!2sBali%20Relaxing%20Resort%20%26%20Spa%2C%20Jl.%20Pratama%20No.62%2C%20Benoa%2C%20South%20Kuta%2C%20Badung%20Regency%2C%20Bali%2080361!3m2!1d-8.7778115!2d115.22400809999999!5e0!3m2!1sen!2sid!4v1734378350236!5m2!1sen!2sid',
            description: t("hotel.relaxing"),
            distance: 16.7,
            time: 33,
            promoCode: '',
            bookingNumber: '+62 8577 3708 2713 (Eva)',
            roomTypes: [
                { type: 'Superior Room', price: 700, availability: 15 },
                { type: 'Relaxing Courtyard', price: 850, availability: 5 },
                { type: 'Deluxe Pool View', price: 1100, availability: 15 },
            ],
        },
        {
            name: 'Jimbaran Bay Beach Resort & Spa',
            star: 4,
            images: ['/img/hotel/jimbaran.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d63084.37675868991!2d115.12606171881475!3d-8.807333099337004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd2446667a4b4bd%3A0xb7a44c266f034787!2sJimbaran%20Bay%20Beach%20Resort%20%26%20Spa%2C%20Jalan%20Pantai%20Kedonganan%2C%20Kedonganan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.7664683!2d115.1700616!5e0!3m2!1sen!2sid!4v1734378670645!5m2!1sen!2sid',
            description: t("hotel.jimbaran"),
            distance: 13.3,
            time: 32,
            promoCode: 'PNM008',
            bookingNumber: '+62 8113 8137 88',
            roomTypes: [
                { type: 'Manukrawa', price: 750, availability: 21 },
                { type: 'Payembrahma', price: 800, availability: 1 },
                { type: 'Puspanjali', price: 950, availability: 21 },
                { type: 'Pendet', price: 2400, availability: 6 },
                { type: 'Legong Keraton', price: 2150, availability: 2 },
                { type: 'Gopala', price: 2150, availability: 2 },
                { type: 'Janger', price: 2650, availability: 2 },
                { type: 'Kecak', price: 3750, availability: 1 },
            ],
        },
        {
            name: 'Le Grande Bali Pecatu',
            star: 5,
            images: ['/img/hotel/grande.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d31540.525321656452!2d115.12626352735909!3d-8.826809136148402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd24525397582c3%3A0x1016b497fa7d8044!2sLe%20Grande%20Hotel%20Bali%2C%20Pecatu%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8064273!2d115.130563!5e0!3m2!1sen!2sid!4v1734378984833!5m2!1sen!2sid',
            description: t("hotel.grande"),
            distance: 8.3,
            time: 20,
            promoCode: 'HARLEY DAVIDSON GROUP',
            bookingNumber: '+62 8969 8995 886 (Maydia)',
            roomTypes: [
                { type: 'Deluxe Room', price: 875, availability: 130 },
                { type: 'One Bedroom Suite', price: 1300, availability: 43 },
                { type: 'Two Bed', price: 1850, availability: 12 },
            ],
        },
        {
            name: 'FOX Hotel Jimbaran Beach',
            star: 4,
            images: ['/img/hotel/fox.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d63083.86209539576!2d115.11965251882613!3d-8.81034949859064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd2446183895d27%3A0x3331c4e03ab6f849!2sFOX%20Hotel%20Jimbaran%20Beach%2C%20Jalan%20Yoga%20Perkanthi%2C%20Jimbaran%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.7726355!2d115.1709358!5e0!3m2!1sen!2sid!4v1734379653256!5m2!1sen!2sid',
            description: t("hotel.fox"),
            distance: 10.8,
            time: 32,
            promoCode: 'HRELYFJBB',
            bookingNumber: '+62 8776 1724 818 (Maydia)',
            roomTypes: [
                { type: 'Deluxe Room Shower', price: 920, availability: 60 },
            ],
        },
        {
            name: 'Swiss-Belresort Pecatu',
            star: 4,
            images: ['/img/hotel/swiss.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d31540.32676886374!2d115.12752492736132!3d-8.829131536004782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd2452fae6c8a13%3A0x91e6af67ff151c4d!2sSwiss-belresort%20Pecatu%2C%20Pecatu%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8099481!2d115.1318948!5e0!3m2!1sen!2sid!4v1734380958780!5m2!1sen!2sid',
            description: t("hotel.swiss"),
            distance: 7.3,
            time: 18,
            promoCode: 'HARLEYDAVIDSONRPE',
            bookingNumber: '+62 8126 2219 418',
            roomTypes: [
                { type: 'Deluxe Room', price: 980, availability: 79 },
                { type: 'Deluxe Balcony', price: 1180, availability: 30 },
                { type: 'Executive Suite', price: 1500, availability: 2 },
            ],
        },
        {
            name: 'Klapa Resort Uluwatu',
            star: 5,
            images: ['/img/hotel/klapa.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d31540.771132470436!2d115.12122967735644!3d-8.823933136326273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd2453fbf491ff5%3A0x7daa795a004e5cba!2sKlapa%20Resort%2C%20Jalan%20Raya%20Uluwatu%20Jalan%20New%20Kuta%20Beach%2C%20Pecatu%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.7998175!2d115.11960309999999!5e0!3m2!1sen!2sid!4v1734380876029!5m2!1sen!2sid',
            description: t("hotel.klapa"),
            distance: 9.5,
            time: 25,
            promoCode: 'HARLEY2025 (min 2 nights)',
            bookingNumber: '+62 8950 0087 93 (Cindy)',
            roomTypes: [
                { type: 'Studio Garden View', price: 1050, availability: 48 },
            ],
        },
        {
            name: 'Royal Tulip Springhill Resort Jimbaran',
            star: 5,
            images: ['/img/hotel/tulip.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d63082.21662536797!2d115.11364321886226!3d-8.819986596206228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd245bd2e952675%3A0x26aa83a429770fdb!2sRoyal%20Tulip%20Springhill%20Resort%20Jimbaran%2C%20Jalan%20Jimbaran%20Hijau%20Kelod%2C%20Jimbaran%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.7925717!2d115.14486319999999!5e0!3m2!1sen!2sid!4v1734380849623!5m2!1sen!2sid',
            description: t("hotel.tulip"),
            distance: 9.9,
            time: 30,
            promoCode: 'HARLEYDAVIDSON25',
            bookingNumber: '+62 8123 6128 955 (Siska)',
            roomTypes: [
                { type: 'Deluxe Room', price: 1200, availability: 35 },
                { type: 'Family Suite', price: 2800, availability: 15 },
            ],
        },
        {
            name: 'La Meridien Bali-Jimbaran',
            star: 5,
            images: ['/img/hotel/meridien.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d63083.060448151526!2d115.1167324188438!3d-8.815045847428628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd2448cb6abaa47%3A0xee5477959308fc68!2sLe%20Meridien%20Bali%20Jimbaran%2C%20Jalan%20Bukit%20Permai%2C%20Jimbaran%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.781780099999999!2d115.1655883!5e0!3m2!1sen!2sid!4v1734380820570!5m2!1sen!2sid',
            description: t("hotel.meridien"),
            distance: 11.6,
            time: 30,
            promoCode: '',
            bookingNumber: '',
            roomTypes: [
                { type: 'ROH Classic / Lagoon View Room', price: 2400, availability: 50 },
            ],
        },
        {
            name: 'Renaissance Bali Uluwatu',
            star: 5,
            images: ['/img/hotel/renaissance.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d31540.198144424918!2d115.13448712736266!3d-8.830635685911759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd244d47a29a8b7%3A0x8e70c2207d497c91!2sRenaissance%20Bali%20Uluwatu%20Resort%20%26%20Spa%2C%20Jalan%20Pantai%20Balangan%20I%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.812979!2d115.14485499999999!5e0!3m2!1sen!2sid!4v1734380788102!5m2!1sen!2sid',
            description: t("hotel.renaissance"),
            distance: 6.7,
            time: 31,
            promoCode: '',
            bookingNumber: '',
            roomTypes: [
                { type: 'Deluxe', price: 2600, availability: 100 },
                { type: 'Junior Suite', price: 3400, availability: 51 },
                { type: 'Executive Suite', price: 3600, availability: 43 },
            ],
        },
        {
            name: 'Radisson Bali',
            star: 5,
            images: ['/img/hotel/radisson.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d31540.365296808184!2d115.11626202736086!3d-8.828680936032645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd25ac456fe003f%3A0xaf85b08d7745dfc1!2sRadisson%20Blu%20Resort%2C%20Bali%20Uluwatu%2C%20Jalan%20Pemutih%20Jalan%20Labuansait%2C%20Pecatu%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8090744!2d115.11042239999999!5e0!3m2!1sen!2sid!4v1734380751671!5m2!1sen!2sid',
            description: t("hotel.radisson"),
            distance: 10.4,
            time: 31,
            promoCode: 'HARLEYFEST',
            bookingNumber: '+62 8770 1043 092 (Reny)',
            roomTypes: [
                { type: 'Deluxe Room', price: 2850, availability: 65 },
            ],
        },
        {
            name: 'Anantara Uluwatu Bali Resort',
            star: 5,
            images: ['/img/hotel/anantara.png'],
            map: 'https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d31540.387159223767!2d115.11532302736063!3d-8.82842523604847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x2dd25be3c65bc987%3A0xfadb552ebfa90802!2sMelasti%20Beach%2C%20Jalan%20Melasti%20Ungasan%2C%20Ungasan%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8482935!2d115.1631697!4m5!1s0x2dd24545ddf194bf%3A0xf4359f4c07de6843!2sAnantara%20Uluwatu%20Bali%20Resort%2C%20Sono%20Teppanyaki%2C%20Jalan%20Labuansait%2C%20Pecatu%2C%20Badung%20Regency%2C%20Bali!3m2!1d-8.8085375!2d115.1079094!5e0!3m2!1sen!2sid!4v1734380715157!5m2!1sen!2sid',
            description: t("hotel.anantara"),
            distance: 11.1,
            time: 35,
            promoCode: 'HARLEYFEB25',
            bookingNumber: '+62 8123 8361 35 (nsuindyarsri@anantara.com)',
            roomTypes: [
                { type: 'Ocean View Suite', price: 5700, availability: 20 },
                { type: 'Antara Pool Suite', price: 6500, availability: 1 },
                { type: 'Ocean View Pool Suite', price: 7200, availability: 5 },
                { type: 'Ocean Front Suite', price: 7900, availability: 9 },
                { type: 'Ocean Front Pool Suite', price: 9800, availability: 3 },
            ],
        },
    ];

    const [hotels, setHotels] = useState(initialHotels);
    const [openHotel, setOpenHotel] = useState(null);

    const toggleHotel = (index) => {
        setOpenHotel(openHotel === index ? null : index);
    };

    const sortHotels = (criterion) => {
        const sortedHotels = [...hotels];
        if (criterion === 'price') {
            sortedHotels.sort((a, b) => {
                const minPriceA = Math.min(...a.roomTypes.map((room) => room.price));
                const minPriceB = Math.min(...b.roomTypes.map((room) => room.price));
                return minPriceA - minPriceB;
            });
        } else if (criterion === 'distance') {
            sortedHotels.sort((a, b) => a.distance - b.distance);
        }
        setHotels(sortedHotels);
    };

    useEffect(() => {
        sortHotels("distance")
    }, [])

    return (
        <div className="mx-auto px-8 md:px-24 py-24 mt-12 text-black">

            <h1 className="text-4xl font-bold mb-12 text-center text-white uppercase">{t("hotel.recommendation")}</h1>

            {/* Sorting Options */}
            {/* <div className="flex justify-end mb-4">
                <select
                    onChange={(e) => sortHotels(e.target.value)}
                    className="border rounded-lg px-4 py-2 bg-gray-100 hover:cursor-pointer"
                >
                    <option value="distance">Sort by</option>
                    <option value="price">Price</option>
                    <option value="distance">Distance</option>
                </select>
            </div> */}

            {hotels.map((hotel, index) => {
                const isOpen = openHotel === index;

                return (
                    <div
                        key={index}
                        className="rounded-lg shadow-lg mb-4 overflow-hidden"
                    >
                        {/* Hotel Header */}
                        <div
                            className="w-full bg-gray-100 md:pr-8 flex flex-col md:flex-row items-center justify-between"
                        >
                            {/* Grid Layout for Image, Details, and Price */}
                            <div className="flex flex-col md:flex-row w-full items-center gap-4 md:gap-8">
                                {/* Left side: Hotel Image */}
                                <div className="h-40 md:h-52 w-full md:w-96">
                                    <img
                                        src={hotel.images[0]}
                                        alt={hotel.name}
                                        className="w-full md:w-auto h-44 md:h-full object-cover"
                                    />
                                </div>

                                <div className="flex flex-col justify-between h-full w-full p-4 md:p-0">
                                    {/* Middle side: Hotel Details (Name and Distance) */}
                                    <div className="flex flex-col justify-start text-left space-y-0">
                                        <span className="text-xl md:text-2xl font-bold flex md:items-center flex-col md:flex-row space-x-1 mb-2">
                                            {hotel.name}
                                            <div className='flex'>
                                                {Array.from({ length: hotel.star }).map((_, index) => (
                                                    <FaStar className='text-sm md:ml-2 text-yellow-400' />
                                                ))}
                                            </div>

                                        </span>
                                        <span className="text-sm md:text-lg text-gray-700">±{hotel.distance} km</span>
                                        <span className="text-sm md:text-sm text-gray-700">{hotel.time} {t("hotel.distance")}</span>
                                    </div>

                                    <div className='mt-8 flex flex-col items-start'>
                                        {hotel.promoCode &&
                                            (
                                                <>
                                                    <span className='text-xs text-gray-500 uppercase'>{t("hotel.promo")}</span>
                                                    <p className='text-green-600 text-lg'>{hotel.promoCode}</p>
                                                </>
                                            )}
                                    </div>


                                    {/* Right side: Hotel Price */}
                                    {/* <div className="flex flex-col justify-end h-full text-left md:mt-4 mt-8">
                                        <span className="text-gray-600 text-xs md:text-sm">Starts from</span>
                                        <span className="text-xl md:text-2xl font-semibold">IDR {hotel.roomTypes[0].price}K</span>
                                    </div> */}
                                </div>
                            </div>

                            {/* Caret Icon */}
                            <button
                                onClick={() => toggleHotel(index)}
                                className={`w-full h-12 md:h-48 md:w-24 text-4xl md:text-4xl text-gray-500 flex items-center justify-center mt-0 mb-4 md:mb-0 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
                                <FaCaretDown />
                            </button>
                        </div>

                        {/* Hotel Details */}
                        {openHotel === index && (
                            <div className="p-4 bg-white">
                                {/* Images */}
                                {/* <div className="grid grid-cols-4 gap-2 mb-4 justify-center">
                                {hotel.images.map((image, imgIndex) => (
                                    <img
                                        key={imgIndex}
                                        src={image}
                                        alt={`${hotel.name} - ${imgIndex + 1}`}
                                        className="w-full h-40 object-cover rounded-lg"
                                    />
                                ))}
                            </div> */}

                                {/* Description and Map side by side */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 py-8">
                                    {/* Embedded Map */}
                                    <div>
                                        <iframe
                                            src={hotel.map}
                                            className="w-full h-52 border rounded-lg"
                                            allowFullScreen
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            title={`Map of ${hotel.name}`}
                                        ></iframe>
                                    </div>
                                    {/* Description */}
                                    <div className="text-gray-700 text-left flex items-center">
                                        <p>{hotel.description}</p>
                                    </div>
                                </div>

                                {/* Table-like Structure */}
                                <div className="flex flex-row gap-8 mb-4">
                                    {/* Promo Code */}
                                    {hotel.promoCode &&
                                        <div className="flex flex-col items-start">
                                            <span className='w-fit text-gray-500 text-xs uppercase'>{t("hotel.promo")}</span>
                                            <span className='text-green-600'>{hotel.promoCode}</span>
                                        </div>
                                    }

                                    {/* Booking Number */}
                                    {hotel.bookingNumber &&
                                        <div className="flex flex-col items-start">
                                            <span className='w-fit text-gray-500 text-xs uppercase'>{t("hotel.booking")}</span>
                                            <span
                                                className="text-gray-700"
                                            >
                                                {hotel.bookingNumber}
                                            </span>
                                        </div>
                                    }

                                </div>

                                {/* Room Types and Prices Table */}
                                {/* <div className="grid grid-cols-3 gap-4 mb-4">
                                    <div className="font-bold">Room Type</div>
                                    <div className="font-bold">Price</div>
                                    <div className="font-bold">Availability</div>
                                    {hotel.roomTypes.map((room, roomIndex) => (
                                        <React.Fragment key={roomIndex}>
                                            <div>{room.type}</div>
                                            <div>IDR {room.price}K</div>
                                            <div>{room.availability} rooms available</div>
                                        </React.Fragment>
                                    ))}
                                </div> */}
                            </div>
                        )}


                    </div>
                )
            })}
        </div>
    );
};

export default HotelRecommendation;
